import React, { PureComponent } from 'react';
import SEO from '../components/seo';
import Image from '../components/image';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core/Typography';

const styles = theme => ({
    div: {

        ...theme.responsivePadding,

        display: `flex`,
        flexDirection: `column`,
        justifyContent: `center`,
        alignItems: `center`,

    },
    h1: {
        fontSize: theme.typeScale['30px'],
        marginBottom: theme.spacing.unit,
    }

});

class NotFoundPage extends PureComponent {
    render() {
        const {classes} = this.props;
        const {div, h1} = classes;
        return (
            <div className={div}>
                <SEO title="404: Not found"/>
                <h1 className={h1}>好悲催啊</h1>
                <Image/>
                <p>you hit a route that doesn&#39;t exist...</p>
            </div>);
    }
}


export default withStyles(styles)(NotFoundPage);
